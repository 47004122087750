body,
html {
  margin: 0;
  padding: 0;
  height: 100%; /* Ensure body and html occupy the full height */
}

.page-container {
  position: relative; /* This makes it the reference point for absolutely positioned children */
  min-height: 100vh; /* Ensure it spans the full viewport height */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.semicircle {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

.footer-content {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 1.75vw;
  text-align: center;
  /* max-width: 500px;
  max-height: 350px; */
}
